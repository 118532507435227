export const LOGIN_LOADING = 'LOGIN_LOADING';
export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USER_BY_ID_LOADING = 'GET_USER_BY_ID_LOADING';
export const CREATE_USER_LOADING = 'CREATE_USER_LOADING';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const UPDATE_SETTINGS_LOADING = 'UPDATE_SETTINGS_LOADING';
export const GET_EXTERNAL_RADS_LOADING = 'GET_EXTERNAL_RADS_LOADING';
export const GET_INTERNAL_RADS_LOADING = 'GET_INTERNAL_RADS_LOADING';
export const GET_PROVIDERS_LOADING = 'GET_PROVIDERS_LOADING';
export const GET_FACILITIES_LOADING = 'GET_FACILITIES_LOADING';
export const GET_EXTERNAL_RAD_LOADING = 'GET_EXTERNAL_RAD_LOADING';
export const GET_INTERNAL_RAD_LOADING = 'GET_INTERNAL_RAD_LOADING';
export const GET_PROVIDER_LOADING = 'GET_PROVIDER_LOADING';
export const GET_FACILITY_LOADING = 'GET_FACILITY_LOADING';
export const POST_RESEND_MEDICAL_GROUPS_INVITATION_LOADING =
  'POST_RESEND_MEDICAL_GROUPS_INVITATION_LOADING';
export const ADD_EXTERNAL_RAD_LOADING = 'ADD_EXTERNAL_RADS_LOADING';
export const ADD_INTERNAL_RAD_LOADING = 'ADD_INTERNAL_RADS_LOADING';
export const ADD_PROVIDER_LOADING = 'ADD_PROVIDER_LOADING';
export const ADD_FACILITY_LOADING = 'ADD_FACILITY_LOADING';
export const EDIT_FACILITY_LOADING = 'EDIT_FACILITY_LOADING';
export const EDIT_EXTERNAL_RAD_LOADING = 'EDIT_EXTERNAL_RADS_LOADING';
export const EDIT_INTERNAL_RAD_LOADING = 'EDIT_INTERNAL_RADS_LOADING';
export const DELETE_EXTERNAL_RAD_LOADING = 'DELETE_EXTERNAL_RADS_LOADING';
export const DELETE_INTERNAL_RAD_LOADING = 'DELETE_INTERNAL_RADS_LOADING';
export const DELETE_PROVIDER_LOADING = 'DELETE_PROVIDER_LOADING';
export const DELETE_FACILITY_LOADING = 'DELETE_FACILITY_LOADING';
export const REQUEST_PROVIDER_SERVICE_LOADING =
  'REQUEST_PROVIDER_SERVICE_LOADING';
export const GET_PLAN_INFORMATION_LOADING = 'GET_PLAN_INFORMATION_LOADING';
export const CHANGE_BILLING_ADDRESS_LOADING = 'CHANGE_BILLING_ADDRESS_LOADING';
export const CHANGE_PAYMENT_CARD_LOADING = 'CHANGE_PAYMENT_CARD_LOADING';
export const CHANGE_PLAN_LOADING = 'CHANGE_PLAN_LOADING';
export const UPGRADE_PLAN_LOADING = 'UPGRADE_PLAN_LOADING';
export const GET_STRIPE_CLIENT_SECRET_LOADING =
  'GET_STRIPE_CLIENT_SECRET_LOADING';
export const GET_GROUP_LOADING = 'GET_GROUP_LOADING';
export const UPDATE_GROUP_LOADING = 'UPDATE_GROUP_LOADING';
export const GET_PHYSICIANS_LOADING = 'GET_PHYSICIANS_LOADING';
export const GET_PHYSICIAN_BY_ID_LOADING = 'GET_PHYSICIAN_BY_ID_LOADING';
export const CREATE_PHYSICIAN_LOADING = 'CREATE_PHYSICIAN_LOADING';
export const UPDATE_PHYSICIAN_LOADING = 'UPDATE_PHYSICIAN_LOADING';
export const DELETE_PHYSICIAN_LOADING = 'DELETE_PHYSICIAN_LOADING';
export const GET_PORTAL_LOADING = 'GET_PORTAL_LOADING';
export const GET_ACTIVE_STUDIES_LOADING = 'GET_ACTIVE_STUDIES_LOADING';
export const GET_GROUP_DETAIL_LOADING = 'GET_GROUP_DETAIL_LOADING';
export const GET_OVERVIEW_LOADING = 'GET_OVERVIEW_LOADING';
export const GET_STUDY_DETAILS_LOADING = 'GET_STUDY_DETAILS_LOADING';
export const GET_RADIOLOGIST_PERFORMANCE_LOADING =
  'GET_RADIOLOGIST_PERFORMANCE_LOADING';
export const GET_PROVIDER_PERFORMANCE_LOADING =
  'GET_PROVIDER_PERFORMANCE_LOADING';
export const SET_BANK_PAYMENT_LOADING = 'SET_BANK_PAYMENT_LOADING;';
export const GET_STRIPE_DATA_LOADING = 'GET_STRIPE_DATA_LOADING';
export const CREATE_STRIPE_SESSION_LOADING = 'CREATE_STRIPE_SESSION_LOADING';
export const SECOND_OPINION_LOADING = 'SECOND_OPINION_LOADING';
export const ONBOARDING_LOADING = 'ONBOARDING_LOADING';
export const CREATE_DEVICE_LOADING = 'CREATE_DEVICE_LOADING';
export const UPDATE_DEVICE_LOADING = 'UPDATE_DEVICE_LOADING';
export const DELETE_DEVICE_LOADING = 'DELETE_DEVICE_LOADING';
export const GET_DEVICES_LOADING = 'GET_DEVICES_LOADING';
export const GET_DEVICES_STATUS_LOADING = 'GET_DEVICES_STATUS_LOADING';
export const GET_SOURCE_FACILITIES_CHART_LOADING =
  'GET_SOURCE_FACILITIES_CHART_LOADING';
export const GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING =
  'GET_SOURCE_REFERRING_FACILITIES_CHART_LOADING';
export const GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING =
  'GET_SOURCE_REFERRING_PHYSICIANS_CHART_LOADING';
export const GET_REFERRING_PHYSICIANS_LIST_LOADING =
  'GET_REFERRING_PHYSICIANS_LIST_LOADING';
export const GET_REFERRING_PHYSICIANS_LOADING =
  'GET_REFERRING_PHYSICIANS_LOADING';
export const GET_REFERRING_PHYSICIAN_LOADING =
  'GET_REFERRING_PHYSICIAN_LOADING';
export const ADD_REFERRING_PHYSICIAN_LOADING =
  'ADD_REFERRING_PHYSICIAN_LOADING';
export const EDIT_REFERRING_PHYSICIAN_LOADING =
  'EDIT_REFERRING_PHYSICIAN_LOADING';
export const DELETE_REFERRING_PHYSICIAN_LOADING =
  'DELETE_REFERRING_PHYSICIAN_LOADING';
export const GET_REFERRING_FACILITIES_LOADING =
  'GET_REFERRING_FACILITIES_LOADING';
export const GET_REFERRING_FACILITIES_LIST_LOADING =
  'GET_REFERRING_FACILITIES_LIST_LOADING';
export const GET_REFERRING_FACILITY_LOADING = 'GET_REFERRING_FACILITY_LOADING';
export const ADD_REFERRING_FACILITY_LOADING = 'ADD_REFERRING_FACILITY_LOADING';
export const EDIT_REFERRING_FACILITY_LOADING =
  'EDIT_REFERRING_FACILITY_LOADING';
export const DELETE_REFERRING_FACILITY_LOADING =
  'DELETE_REFERRING_FACILITY_LOADING';
  export const POST_USER_SIGNATURE_UPLOAD_LOADING =
  'POST_USER_SIGNATURE_UPLOAD_LOADING';