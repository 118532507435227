import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal, Icon, Card, Empty, Radio, Switch, Checkbox, Tooltip, Form, Spin } from 'antd';
import { qualificationOptions, signatureFontOptions } from '../../../store/constants';
import { USER_TYPE } from "../../../utils/filters";
import moment from "moment";
import { Field, getFormSyncErrors, getFormValues, reduxForm } from "redux-form";
import { InputPhoneNumberField, InputRadioButtonField, InputSelectField, InputSwitchField, InputTextField } from "../../../components/FormParts";
import { connect } from "react-redux";
import InputAutoCompleteField from "../../../components/FormParts/InputAutoCompleteField";
import UploadField from "../../../components/FormParts/UploadField";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";

const dateWithoutTime = moment().format('L')

const dateWithTime = moment().format('MM/DD/YYYY, HH:mm')

const dateTabs = [
    { text: dateWithoutTime, value: 'L' },
    { text: dateWithTime, value: "MM/DD/YYYY, HH:mm" },
];
const UserSignatureForm = ({ onClose, user, onSubmitSignature, signatureFormValues, change, handleSubmit, formErrors, azureBlobSAS, loading }) => {

    const { auto_generated, signature_name, designation, phone_number, qualifications, signature, signatureNew, signature_text = "", credentials = "", name = "", title = "", font_name, digitally_signed, reporting_date_format, display_phone_number } = signatureFormValues || {}
    const { t } = useTranslation();
    const [agreement, setAgreement] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [optionalField, setOptionalField] = useState({
        name: false,
        designation: false,
        phone_number: false,
        report_date: false,
        qualifications: false,
        signature_text: false
    });

    useEffect(() => {
        let fieldOptions = { ...optionalField }
        if (name || title || credentials) {
            fieldOptions = { ...fieldOptions, name: true }
        }
        if (designation) {
            fieldOptions = { ...fieldOptions, designation: true }
        }
        if (display_phone_number) {
            fieldOptions = { ...fieldOptions, phone_number: true }
        }
        if (reporting_date_format) {
            fieldOptions = { ...fieldOptions, report_date: true }
        }
        if (qualifications) {
            fieldOptions = { ...fieldOptions, qualifications: true }
        }
        if (signature_text) {
            fieldOptions = { ...fieldOptions, signature_text: true }
        }
        setOptionalField(fieldOptions)
    }, [signatureFormValues]);


    let getSignatureImageName = () => {
        if (isString(signature)) {
            let array = signature.split("/");
            let imageName = array[array.length - 1];
            return imageName
        } else {
            return signature.name
        }
    }
    const visiblePreviewSignature = signature || (optionalField.name && name != "") || (optionalField.name && title != "") || (optionalField.name && credentials != "") || (optionalField.designation && designation != "") || (optionalField.phone_number && phone_number) || (optionalField.qualification && qualifications) || (optionalField.report_date && reporting_date_format) || (optionalField.signature_text && signature_text)
    return (<Modal
        visible={true}
        onCancel={onClose}
        footer={null}
        title={<div><Icon type='arrow-left' onClick={onClose} /> <span style={{ marginLeft: '10px' }}>{t('user_administration.field.signature')}</span></div>}
        wrapClassName='lateral-modal signature-modal'
    >
        <Form>
            <Spin spinning={loading}>
                <div className='signature-form'>
                    <div className='signature-header'>
                        <div className='header-wrapper'>
                            <div className='preview' id='preview'>
                                <Card title={t('user_administration.signature_preview')} size='small'>
                                    {visiblePreviewSignature ?
                                        <div className="preview-data-container">
                                            {digitally_signed && <div className="digitally-signed">Digitally Signed By:</div>}
                                            {auto_generated && <div className="auto-signature" style={{ fontFamily: font_name ? font_name : "Inter" }}>{name}</div>}
                                            {!auto_generated && signature &&
                                                <div><img className="signature-image" src={signature && signature.preview ? signature.preview : signature + "?" + azureBlobSAS} alt="signature" /></div>}
                                            {(title || name || credentials) && <div className="signature-name">{title ? title + " " : ""}{name}{credentials ? " ," + credentials : ""}</div>}
                                            {optionalField.designation && designation && <div className="signature-role">{designation}</div>}
                                            {optionalField.phone_number && phone_number && display_phone_number && <div className="signature-phone">{phone_number}</div>}
                                            {optionalField.report_date && reporting_date_format && <div className="signature-report-date">Reported: {reporting_date_format}</div>}
                                            {optionalField.qualifications && qualifications && <div className="signature-qualification">{qualifications}</div>}
                                            {optionalField.signature_text && signature_text && <div className="signature-free-text">{signature_text}</div>}
                                        </div>
                                        : <div> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<div className='empty-sign-text'>{t('user_administration.add_your_details')}</div>} /></div>}
                                </Card>
                            </div>
                            <div className='header-separator' />
                        </div>
                    </div>
                    <div className='signature-body'>
                        <div className='signature-mode'>
                            <div className='label'>{t('user_administration.field.signature')}</div>
                            <div>
                                <Field
                                    name="auto_generated"
                                    className='signature-mode-radio'
                                    component={InputRadioButtonField([{ text: t('user_administration.field.auto_generated'), value: true }, { text: t('user_administration.field.upload_signature'), value: false }])}
                                />
                            </div>
                        </div>
                        <div className='digitally-signed-switch'>
                            <Row type='flex' justify='space-between' align='middle'>
                                <Col><div className='label'>{t('user_administration.add')} “Digitally Signed By”</div></Col>
                                <Col>
                                    <Field
                                        name="digitally_signed"
                                        className='signature-mode-radio'
                                        component={InputSwitchField}
                                        checkedChildren={<Icon type="check" />}
                                        unCheckedChildren={<Icon type="close" />}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {auto_generated ? <div className='font-field'>
                            <div className='label'>{t('user_administration.field.font')} <span className='asterisk-text'>*</span></div>
                            <Field
                                name="font_name"
                                component={InputSelectField(signatureFontOptions)}
                                size="large"
                                isFontOptions={true}
                            />
                        </div>
                            : <div className='upload-btn'>
                                <Row gutter={[8, 8]}>
                                    {signature && <Col>
                                        <Row type='flex' justify='space-between'>
                                            <Col><div className='upload-file-item'><Radio checked /> <Icon type="paper-clip" /> <Tooltip title={getSignatureImageName()}><div className='filename'>{getSignatureImageName()}</div></Tooltip></div></Col>
                                            <Col><Icon style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => change("signature", null)} type="delete" /></Col>
                                        </Row>
                                    </Col>}
                                    <Col>
                                        <Field name="signature" className='upload-btn-input' accept='image/png, image/jpeg, image/jpg' showUploadList={false} component={UploadField} />
                                        {!formErrors.signature && <div className='upload-btn-text'>{t('user_administration.field.please_upload_format')}</div>}
                                    </Col>
                                </Row>
                            </div>}
                        <div className='optional-fields'>
                            <div className='label'>{t('user_administration.field.optional_fields')}</div>
                            <div className='optional-field-box'>
                                <Row type="flex" gutter={[8, 8]} align="middle" style={{ flexWrap: 'unset' }}>
                                    <Col span={3}>
                                        <Switch
                                            checked={optionalField.name}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, name: !optionalField.name })
                                                !e && change("name", null)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>{optionalField.name ?
                                        <Row type="flex" gutter={[8, 8]}>
                                            <Col span={4}>
                                                <div className='label'>{t('user_administration.field.title')}</div>
                                                <Field name="title" component={InputTextField} placeholder="Dr." />
                                            </Col>
                                            <Col span={14}>
                                                <div className='label'>{t('user_administration.field.name')}</div>
                                                <Field name="name" component={InputTextField} placeholder={t('user_administration.field.name')} />
                                            </Col>
                                            <Col span={6}>
                                                <div className='label'>{t('user_administration.field.credentials')}</div>
                                                <Field name="credentials" component={InputTextField} placeholder="MD" />
                                            </Col>
                                        </Row> : t('user_administration.field.name')}</Col>
                                </Row>
                            </div>
                            <div className='optional-field-box'>
                                <Row type="flex" align="middle" gutter={[8, 8]}>
                                    <Col span={3}>
                                        <Switch checked={optionalField.designation}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, designation: !optionalField.designation });
                                                !e && change("designation", null)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>{optionalField.designation
                                        ?
                                        <Row>
                                            <Col span={24}>
                                                <div className='label'>{t('user_administration.field.role')}</div>
                                                <Field name="designation" component={InputSelectField(USER_TYPE)} placeholder={t('user_administration.field.select_role')} />
                                            </Col>
                                        </Row>
                                        : t('user_administration.field.role')}
                                    </Col>
                                </Row>
                            </div>
                            <div className='optional-field-box'>
                                <Row type="flex" align="middle" gutter={[8, 8]}>
                                    <Col span={3}>
                                        <Switch checked={optionalField.phone_number}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, phone_number: !optionalField.phone_number })
                                                change("display_phone_number", e)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>{optionalField.phone_number
                                        ?
                                        <Row>
                                            <Col span={24}>
                                                <div className='label'>{t('user_administration.field.phone_number')}</div>
                                                <Field name="phone_number" component={InputPhoneNumberField} defaultCountry='sg' disableAreaCodes />
                                            </Col>
                                        </Row>
                                        : t('user_administration.field.phone_number')}</Col>
                                </Row>
                            </div>
                            <div className='optional-field-box'>
                                <Row type="flex" align="middle" gutter={[8, 8]}>
                                    <Col span={3}>
                                        <Switch checked={optionalField.report_date}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, report_date: !optionalField.report_date })
                                                !e && change("reporting_date_format", null)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>
                                        {optionalField.report_date ?
                                            <Row>
                                                <Col span={24}>
                                                    <div className='label'>{t('user_administration.field.reporting_date')}</div>
                                                    <div>
                                                        <Field name="reporting_date_format" className="reporting-date-radio" component={InputRadioButtonField(dateTabs)} />
                                                    </div>
                                                    <div className="reporting-date-text">{t('user_administration.field.reporting_text')}</div>
                                                </Col>
                                            </Row>
                                            : t('user_administration.field.reporting_date')}
                                    </Col>
                                </Row>
                            </div>
                            <div className='optional-field-box'>
                                <Row type="flex" align="middle" gutter={[8, 8]}>
                                    <Col span={3}>
                                        <Switch checked={optionalField.qualifications}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, qualifications: !optionalField.qualifications });
                                                !e && change("qualifications", null)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>{optionalField.qualifications
                                        ?
                                        <Row>
                                            <Col span={24}>
                                                <div className='label'>{t('user_administration.field.qualifications')}</div>
                                                <Field name="qualifications" placeholder={t('user_administration.field.qualifications')} component={InputAutoCompleteField} dataSource={qualificationOptions} style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                        : t('user_administration.field.qualifications')}
                                    </Col>
                                </Row>
                            </div>
                            <div className='optional-field-box'>
                                <Row type="flex" align="middle" gutter={[8, 8]}>
                                    <Col span={3}>
                                        <Switch checked={optionalField.signature_text}
                                            onChange={(e) => {
                                                setOptionalField({ ...optionalField, signature_text: !optionalField.signature_text })
                                                !e && change("signature_text", null)
                                            }}
                                            checkedChildren={<Icon type="check" />}
                                            unCheckedChildren={<Icon type="close" />} />
                                    </Col>
                                    <Col span={21}>
                                        {optionalField.signature_text
                                            ?
                                            <Row>
                                                <Col span={24}>
                                                    <div className='label'>{t('user_administration.field.free_text')}</div>
                                                    <Field name="signature_text" placeholder={t('user_administration.field.free_text')} component={InputTextField} />
                                                </Col>
                                            </Row>
                                            : t('user_administration.field.free_text')}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className='signature-footer'>
                        <div className='footer-wrapper'>
                            <div className='content'>
                                <div className='encryption-section'>
                                    <Icon type="lock" theme="filled" className='lock-icon' />{t('user_administration.field.security_encryption')}Your data is protected with 256-bit security encryption.
                                </div>
                                <div className='signature-agreement'>
                                    <div className='checkbox'>
                                        <Checkbox checked={agreement} onChange={() => { setErrorMessage(false); setAgreement(!agreement) }} />
                                    </div>
                                    <div className='text'>{t('user_administration.field.by_clicking_save_signature')}</div>
                                </div>
                                {errorMessage && !agreement && <div className="footer-error error-text">{t('user_administration.field.signature_agreement')}</div>}
                                <Row type='flex' justify='end' gutter={[16, 16]} className='footer-btn'>
                                    <Col><Button onClick={onClose}>{t('button.cancel')}</Button></Col>
                                    <Col><Button disabled={!agreement} htmlType='submit' onClick={handleSubmit} type='primary'>{t('user_administration.field.save_signature')}</Button></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </Form>
    </Modal >)
};
const mapStateToProps = (state) => {
    const formErrors = getFormSyncErrors("userSignatureForm")(state)
    return {
        signatureFormValues: getFormValues("userSignatureForm")(state),
        formErrors,
        loading: state.loading.patchUserSignature
    }
}

const validateSignatureEdit = values => {
    const { signature, signatureNew } = values
    const errors = {}
    if (!signature) {
        errors.signature = 'Please upload your signature before saving.'
    }
    return errors
}

export default connect(mapStateToProps, null)(reduxForm({
    form: 'userSignatureForm',
    validate: validateSignatureEdit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false
})(UserSignatureForm))