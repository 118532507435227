import {
  AnonymousCredential,
  ContainerURL,
  ServiceURL,
  StorageURL
} from '@azure/storage-blob';
import Configuration from '../../utils/config';
import { AZURE_DELEGATION_API_SUBSCRIPTION_KEY, AZURE_DELEGATION_API_URL } from '../../utils/azure-blob-helpers';
import AuthService from '../index';
import type { ApiService, UserProfile } from '../../schemas';
import decrypt from '../../utils/crypto';

const API_SERVICE: ApiService = 'blob-access';

// eslint-disable-next-line import/prefer-default-export
export const getSasTokenForGroupLogo = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `/options/blob-access/group`, {})
    .then(r => r);
};

export const getSasTokenForFacilityLogo = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `/options/blob-access/facility`, {})
    .then(r => r);
};

export const getTeleradSettingsContainerURL = async ({
  containerName,
  azureAddress,
  accessToken
}) => {
  const anonymousCredential = new AnonymousCredential();
  const pipeline = StorageURL.newPipeline(anonymousCredential);
  const serviceURL = new ServiceURL(
    decrypt(accessToken, azureAddress),
    pipeline
  );
  const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);

  return containerURL
}

export const getSharedAccessSignatureForAzureBlobAPICall = async (profile) => {
  try {
    // const { clientID } = AUTH_CONFIG;
    let accessToken = 'Bearer ' + profile.accessToken

    const config = {
      method: 'POST',
      headers: {
        'Authorization': accessToken,
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': AZURE_DELEGATION_API_SUBSCRIPTION_KEY
      },
      body: JSON.stringify({
        'client_id': Configuration.auth0.clientId,
        'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        'id_token': profile.accessToken,
        'target': Configuration.auth0.clientId,
        'api_type': 'azure_blob',
        'scope': 'openid'
      })
    }
    const targetUrl = AZURE_DELEGATION_API_URL
    const response = await window.fetch(targetUrl, config)
    const responseOk = await response.status === 200
    let responseJSON = {}

    responseJSON = await response.json()

    return { responseOk, responseJSON }
  } catch (e) {
    console.error('ERROR: ', e)
    return e
  }
}

