import moment from 'moment';
import { isEmpty, toCamel, transformObject } from '../utils';

export const getSpecialty = specialty => {
  if (!specialty) return [];
  return typeof specialty === 'string' ? [specialty] : specialty.filter(s => s);
};

export const accountSettingsToUser = (accountSettings, profile) => ({
  name: accountSettings.name,
  email: accountSettings.email,
  userMetadata: {
    ...profile.userMetadata,
    phoneNumber: accountSettings.phone ? accountSettings.phone.phone : null,
    homeFacility: accountSettings.facility,
    title: accountSettings.title,
    credentials: accountSettings.credentials,
    userType: accountSettings.userType,
    specialty: getSpecialty(accountSettings.specialty)
  },
  appMetadata: profile.appMetadata
});

export const userToJson = user =>
  JSON.stringify({
    id: user.id,
    name: user.name,
    email: user.email,
    invitee_email: user.invitee_email,
    organization_guid: user.organization_guid,
    organization_name: user.organization_name,
    app_metadata: {
      admin: user.appMetadata.admin,
      main: user.appMetadata.main,
      associated_groups: user.appMetadata.associatedGroups,
      affiliated_group: user.appMetadata.affiliatedGroup,
      approved_facilities: user.appMetadata.approvedFacilities,
      available_for_assignment: user.appMetadata.availableForAssignment,
      allowed_to_delete_study: user.appMetadata.allowed_to_delete_study
    },
    user_metadata: {
      name: user.name,
      phone_number: user.userMetadata.phoneNumber,
      country: user.userMetadata.country,
      prefix: user.userMetadata.prefix,
      user_type: user.userMetadata.userType,
      specialty: getSpecialty(user.userMetadata.specialty),
      signature: user.userMetadata.signature,
      signature_settings: user.userMetadata.signature_settings,
      title: user.userMetadata.title,
      home_facility: user.userMetadata.homeFacility,
      credentials: user.userMetadata.credentials
    }
  });

export const jsonToUser = user => {
  if (Object.entries(user).length > 0) {
    let obj = {
      ...user,
      id: user.id,
      name: user.name,
      email: user.email,
      appMetadata: {
        admin: user.app_metadata.admin,
        main: user.app_metadata.main,
        associatedGroups: user.app_metadata.associated_groups,
        affiliatedGroup: user.app_metadata.affiliated_group,
        approvedFacilities: user.app_metadata.approved_facilities,
        availableForAssignment: user.app_metadata.available_for_assignment,
        allowed_to_delete_study: user.app_metadata.allowed_to_delete_study
      },
      userMetadata: {
        name: user.user_metadata.name,
        phoneNumber: user.user_metadata.phone_number,
        prefix: user.user_metadata.prefix,
        userType: user.user_metadata.user_type,
        specialty: getSpecialty(user.user_metadata.specialty),
        signature: user.user_metadata.signature,
        signature_settings: user.user_metadata.signature_settings,
        title: user.user_metadata.title,
        credentials: user.user_metadata.credentials,
        homeFacility: user.user_metadata.home_facility
      }
    }
    return obj;
  }
  else {
    return user;
  }
};

export const providerToJson = provider =>
  JSON.stringify({
    id: provider.id,
    country: provider.country,
    email_address: provider.email,
    name: provider.name,
    phone_number: provider.phoneNumber && provider.phoneNumber.phone,
    primary_contact: provider.primaryContact,
    study_access: provider.studyAccess
  });

export const jsonToProvider = provider =>
  Object.entries(provider).length > 0
    ? {
      id: provider.id,
      country: provider.country,
      email: provider.email_address,
      name: provider.name,
      phoneNumber: provider.phone_number,
      primaryContact: provider.primary_contact,
      studyAccess: provider.study_access,
      availableTo: provider.available_to,
      pendingAvailableTo: provider.pending_available_to
    }
    : provider;

export const createUserValuesToUser = (values, userGroup, facilities, signature_settings) => {
  let userObject = {
    name: values.name,
    invitee_email: values.email,
    organization_guid: userGroup,
    userMetadata: {
      name: values.name,
      homeFacility: values.homeFacility,
      phoneNumber: values.phoneNumber ? values.phoneNumber.phone : '',
      country: values.phoneNumber ? values.phoneNumber.countryName : '',
      prefix: values.phoneNumber ? values.phoneNumber.dialCode : '',
      specialty: getSpecialty(values.specialty),
      userType: values.userType,
      title: values.title || '',
      credentials: values.credentials || '',
      inviteUserWith: values.inviteUserWith,
    },
    appMetadata: {
      affiliatedGroup: userGroup,
      approvedFacilities: facilities
        .filter(
          f => values.groupFacilities && values.groupFacilities.includes(f.name)
        )
        .map(f => f.guid),
      admin: values.admin,
      main: '0',
      availableForAssignment: values.availableForAssignment || '0',
      allowed_to_delete_study: values.allowed_to_delete_study || '1'
    }
  }
  if (signature_settings && Object.keys(signature_settings).length > 0) {
    const { signature, name, title, credentials, phone_number, qualifications, auto_generated, font_name, signature_name, digitally_signed, signature_text, reporting_date_format, display_phone_number, designation, signatureNew } = signature_settings
    userObject = {
      ...userObject,
      userMetadata: {
        ...userObject.userMetadata,
        name: name || "",
        title: title || "",
        credentials: credentials || "",
        phone_number: phone_number || "",
        signature_settings: {
          auto_generated: auto_generated,
          font_name: font_name || "",
          // signature_name: signature_name || "",
          digitally_signed: digitally_signed || false,
          designation: designation || "",
          display_phone_number: display_phone_number || false,
          reporting_date_format: reporting_date_format || "",
          qualifications: qualifications || "",
          signature_text: signature_text || ""
        }
      }
    }
  }
  return userObject
};

export const editUserValuesToUser = (values, userGroup, facilities, user, signature_settings) => {
  let userObject = {
    name: values.name,
    invitee_email: values.email,
    organization_guid: userGroup,
    userMetadata: {
      name: values.name,
      homeFacility: values.homeFacility,
      phoneNumber: values.phoneNumber ? values.phoneNumber.phone : '',
      country: values.phoneNumber ? values.phoneNumber.countryName : '',
      prefix: values.phoneNumber ? values.phoneNumber.dialCode : '',
      specialty: getSpecialty(values.specialty),
      userType: values.userType,
      title: values.title || '',
      credentials: values.credentials || '',
      inviteUserWith: values.inviteUserWith,
    },
    appMetadata: {
      affiliatedGroup: userGroup,
      approvedFacilities: facilities
        .filter(
          f => values.groupFacilities && values.groupFacilities.includes(f.name)
        )
        .map(f => f.guid),
      admin: values.admin,
      main: '0',
      availableForAssignment: values.availableForAssignment || '0',
      allowed_to_delete_study: values.allowed_to_delete_study || '1'
    }
  }
  if (user && Object.keys(user).length > 0) {  
    userObject = {
      ...userObject,
      userMetadata: {
        ...user.userMetadata,
        ...userObject.userMetadata
      }
    }
  }
  return userObject
};

export const editUserValuesToExtUser = (values, userGroup, facilities) => ({
  name: values.name,
  invitee_email: values.email,
  organization_guid: userGroup,
  userMetadata: {
    name: values.name,
    homeFacility: values.homeFacility || '',
    phoneNumber: values.phoneNumber ? values.phoneNumber.phone : '',
    country: values.phoneNumber ? values.phoneNumber.countryName : '',
    prefix: values.phoneNumber ? values.phoneNumber.dialCode : '',
    specialty: getSpecialty(values.specialty),
    userType: values.userType,
    title: values.title || '',
    credentials: values.credentials || '',
    inviteUserWith: values.inviteUserWith
  },
  appMetadata: {
    affiliatedGroup: null,
    approvedFacilities: facilities
      .filter(
        f => values.groupFacilities && values.groupFacilities.includes(f.name)
      )
      .map(f => f.guid),
    associatedGroups: [userGroup],
    admin: values.admin || '0',
    main: '0'
  }
});

export const editIRValuesToUser = (values, userGroup, facilities) =>
  editUserValuesToUser(
    { ...values, userType: 'Radiologist' },
    userGroup,
    facilities
  );
export const inviteToJson = invite =>
  JSON.stringify({
    invitation_guid: invite.invitationGuid
  });

export const jsonToInvite = invite =>
  Object.entries(invite).length > 0
    ? {
      invitation_guid: invite.invitation_guid,
      name: invite.user_metadata.name,
      ...invite,
    }
    : invite;

export const billingAddressToJson = billingAddress =>
  JSON.stringify({
    first_name: billingAddress.firstName,
    last_name: billingAddress.lastName,
    email: billingAddress.email,
    company: billingAddress.company,
    phone: billingAddress.phone,
    line1: billingAddress.line1,
    line2: billingAddress.line2,
    line3: billingAddress.line3,
    city: billingAddress.city,
    state_code: billingAddress.stateCode,
    state: billingAddress.state,
    zip: billingAddress.zip,
    country: billingAddress.country
  });

const jsonToSubscriptionUpdate = jsonSubscription =>
  isEmpty(jsonSubscription)
    ? {}
    : {
      id: jsonSubscription.id,
      planId: jsonSubscription.plan_id,
      currentTermStart: jsonSubscription.current_term_start,
      currentTermEnd: jsonSubscription.current_term_end
    };

export const jsonToSubscription = jsonSubscription => ({
  id: jsonSubscription.id,
  planId: jsonSubscription.plan_id,
  currentTermStart: jsonSubscription.current_term_start,
  currentTermEnd: jsonSubscription.current_term_end,
  hasScheduledChanges: jsonSubscription.has_scheduled_changes,
  updated: jsonToSubscriptionUpdate(jsonSubscription.updated),
  name: jsonSubscription.plan_data
    ? jsonSubscription.plan_data.name
    : undefined,
  description: jsonSubscription.plan_data
    ? jsonSubscription.plan_data.description
    : undefined,
  amount: jsonSubscription.plan_amount,
  quantity: jsonSubscription.plan_quantity,
  unitPrice: jsonSubscription.plan_unit_price,
  currency: jsonSubscription.currency_code,
  dueInvoices: jsonSubscription.due_invoices_count,
  periodUnit: jsonSubscription.plan_data
    ? jsonSubscription.plan_data.period_unit
    : undefined,
  nextBill: jsonSubscription.next_billing_at
});

const jsonToAddon = jsonAddon => ({
  id: jsonAddon.id,
  name: jsonAddon.name,
  date: new Date(jsonAddon.date * 1000).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }),
  description: jsonAddon.description,
  currency: jsonAddon.currency,
  pricingModel: jsonAddon.pricing_model,
  unitPrice: jsonAddon.unit_amount,
  quantity: jsonAddon.quantity,
  amount: jsonAddon.amount,
  isTaxed: jsonAddon.is_taxed,
  taxAmount: jsonAddon.tax_amount,
  taxExemptReason: jsonAddon.tax_exempt_reasons,
  unitDiscount: jsonAddon.item_level_discount_amount,
  discount: jsonAddon.discount_amount
});

export const jsonToBillingAddress = jsonBilling => ({
  firstName: jsonBilling.first_name ? jsonBilling.first_name : '',
  lastName: jsonBilling.last_name ? jsonBilling.last_name : '',
  email: jsonBilling.email ? jsonBilling.email : '',
  company: jsonBilling.company ? jsonBilling.company : '',
  phone: jsonBilling.phone ? jsonBilling.phone : '',
  line1: jsonBilling.line1 ? jsonBilling.line1 : '',
  line2: jsonBilling.line2 ? jsonBilling.line2 : '',
  line3: jsonBilling.line3 ? jsonBilling.line3 : '',
  city: jsonBilling.city ? jsonBilling.city : '',
  stateCode: jsonBilling.state_code ? jsonBilling.state_code : '',
  state: jsonBilling.state ? jsonBilling.state : '',
  zip: jsonBilling.zip ? jsonBilling.zip : '',
  country: jsonBilling.country ? jsonBilling.country : ''
});

export const jsonToStripeInvoice = jsonInvoice => ({
  ...transformObject(
    jsonInvoice,
    [
      'id',
      'metadata',
      'number',
      'paid',
      'status',
      'subtotal',
      'total',
      'subscription',
      'amount_due',
      'amount_paid',
      'amount_remaining',
      'currency',
      'customer_tax_exempt',
      'ending_balance',
      'hosted_invoice_url',
      'invoice_pdf',
      'receipt_number',
      'starting_balance'
    ],
    toCamel
  ),
  ...transformObject(
    jsonInvoice,
    ['due_date', 'finalized_at', 'period_end', 'period_start'],
    toCamel,
    d => (d ? new Date(d * 1000) : d)
  ),
  tax: jsonInvoice.tax || 0,
  totalTaxAmounts: jsonInvoice.total_tax_amounts
    ? jsonInvoice.total_tax_amounts.map(a => transformObject(a, null, toCamel))
    : [],
  description: jsonInvoice.description || '',
  customerName: jsonInvoice.customer_name || '',
  customerEmail: jsonInvoice.customer_email || '',
  customerShipping: jsonInvoice.customerShipping
    ? transformObject(jsonInvoice.customerShipping, null, toCamel)
    : {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
  lines: jsonInvoice.lines.map(l => transformObject(l, null, toCamel))
});

export const jsonToStripeSubscription = jsonSubscription => ({
  ...Object.assign(
    ...Object.keys(jsonSubscription)
      .filter(key => ['id', 'name', 'currency', 'interval'].includes(key))
      .map(key => ({ [key]: jsonSubscription[key] }))
  ),
  latestInvoice: jsonSubscription.latest_invoice || '',
  intervalCount: jsonSubscription.interval_count,
  unitAmount: jsonSubscription.unit_amount,
  description: jsonSubscription.description || '',
  status: jsonSubscription.status || '',
  quantity: jsonSubscription.quantity || 0,
  start: new Date(jsonSubscription.start * 1000),
  startDate: new Date(jsonSubscription.start_date * 1000),
  currentPeriodStart: new Date(jsonSubscription.current_period_start * 1000),
  currentPeriodEnd: new Date(jsonSubscription.current_period_end * 1000)
});

export const jsonToStripeInvoiceData = jsonInvoiceData => ({
  data: jsonInvoiceData.data.map(i => jsonToStripeInvoice(i)),
  hasMore: jsonInvoiceData.has_more
});

export const jsonToStripeDataInformation = jsonData => ({
  id: jsonData.id,
  balance: jsonData.balance,
  currency: jsonData.currency,
  renewal: new Date(jsonData.renewal * 1000),
  email: jsonData.email || '',
  loaded: true,
  subscriptions: {
    main: jsonToStripeSubscription(jsonData.subscriptions.main),
    addOns: jsonData.subscriptions.add_ons.map(s => jsonToStripeSubscription(s))
  },
  invoices: jsonToStripeInvoiceData(jsonData.invoices)
});

// eslint-disable-next-line camelcase
export const _DEPRECATED_jsonToPlanInformation = jsonPlan => {
  let renewalDate = '';
  let periodicity = '';
  let firstDayPeriod = '';
  let lastDayPeriod = '';
  let totalPaymentPeriod = 0;
  let currency = '';
  let country = '';
  const subscriptions = jsonPlan.subscriptions.plans.map(s => {
    if (renewalDate === '') {
      renewalDate = new Date(s.current_term_end * 1000).toLocaleString(
        'en-GB',
        { year: 'numeric', month: 'long', day: 'numeric' }
      );
    }
    if (periodicity === '') {
      periodicity = s.plan_data ? s.plan_data.period_unit : '';
    }
    if (firstDayPeriod === '') {
      firstDayPeriod = new Date(s.current_term_start * 1000).toLocaleString(
        'en-GB',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }
      );
    }
    if (lastDayPeriod === '') {
      lastDayPeriod = new Date(s.current_term_end * 1000).toLocaleString(
        'en-GB',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }
      );
    }
    if (currency === '') {
      currency = s.currency_code;
    }
    if (country === '') {
      if (s.plan_id.charAt(s.plan_id.length - 3) === '-') {
        country = s.plan_id.substr(s.plan_id.length - 2);
      } else {
        country = 'us';
      }
    }
    const subscription = jsonToSubscription(s);
    totalPaymentPeriod += subscription.amount;
    return subscription;
  });
  const addons = jsonPlan.subscriptions.addons.map(a => {
    const addon = jsonToAddon(a);
    totalPaymentPeriod += addon.amount;
    return addon;
  });
  return {
    subscriptions,
    addons,
    planSituation: {
      country,
      currency,
      renewalDate,
      periodicity,
      firstDayPeriod,
      lastDayPeriod,
      totalPaymentPeriod
    },
    paymentInformation: jsonPlan.payment_information
      ? {
        billingAddress: jsonToBillingAddress(
          jsonPlan.payment_information.billing_address
            ? jsonPlan.payment_information.billing_address
            : {}
        ),
        type: jsonPlan.payment_information.payment_type,
        brand: jsonPlan.payment_information.brand,
        masked: jsonPlan.payment_information.masked,
        expires: jsonPlan.payment_information.expires
      }
      : {},
    balance:
      jsonPlan.balance && jsonPlan.balance > 0 ? jsonPlan.balance / 100 : 0
  };
};

export const jsonToCard = jsonCard => ({
  masked: jsonCard.card.masked_number,
  brand: jsonCard.card.brand,
  expires: `${jsonCard.card.expiry_month}/${jsonCard.card.expiry_year}`,
  id: jsonCard.id
});

export const facilityToJson = (facility, group) => {
  return {
    name: facility.name,
    facility_type: facility.facilityType,
    phone: facility.phoneNumber ? facility.phoneNumber.phone : undefined,
    location: facility.location,
    country: facility.country,
    website: facility.website,
    email: facility.email,
    member_of: group,
    primary_name: facility.primaryName,
    primary_email: facility.primaryEmail,
    contact_number_1: facility.contactNumber1,
    contact_number_2: facility.contactNumber2,
    contact_number_3: facility.contactNumber3,
    facility_logo: facility.facilityLogo,
    letterhead: facility.letterhead !== '' ? facility.letterhead : undefined,
    custom_letterhead: facility.customLetterhead === 'custom'
  };
};

export const jsonToFacility = jsonObject => ({
  name: jsonObject.name,
  guid: jsonObject.guid,
  facilityType: jsonObject.facility_type,
  phoneNumber: jsonObject.phone,
  location: jsonObject.location,
  country: jsonObject.country,
  website: jsonObject.website,
  facilityLogo: jsonObject.facility_logo,
  letterhead: jsonObject.letterhead,
  email: jsonObject.email,
  memberOf: jsonObject.member_of,
  primaryName: jsonObject.primary_name,
  primaryEmail: jsonObject.primary_email,
  contactNumber1: jsonObject.contact_number_1,
  contactNumber2: jsonObject.contact_number_2,
  contactNumber3: jsonObject.contact_number_3,
  customLetterhead: jsonObject.custom_letterhead ? 'custom' : 'default'
});

const checkValue = value => value || '';
const checkNumberValue = value => value || 0;

export const jsonToGroup = jsonGroup => ({
  name: checkValue(jsonGroup.name),
  facilityType: checkValue(jsonGroup.facility_type),
  location: checkValue(jsonGroup.location),
  officeNumber: checkValue(jsonGroup.office_number),
  emailAddress: checkValue(jsonGroup.email_address),
  website: checkValue(jsonGroup.website),
  logo: checkValue(jsonGroup.logo),
  hasLetterhead: jsonGroup.has_letterhead ? 'yes' : 'no',
  letterhead: checkValue(jsonGroup.letterhead),
  footerSize: jsonGroup.footer_size ? jsonGroup.footer_size : 100,
  headerSize: jsonGroup.header_size ? jsonGroup.header_size : 200
});

export const groupToJson = group => ({
  name: group.name,
  available_to: group.availableTo,
  facility_type: group.facilityType,
  location: group.location,
  office_number: group.officeNumber ? group.officeNumber.phone : undefined,
  email_address:
    group.emailAddress && group.emailAddress !== ''
      ? group.emailAddress
      : undefined,
  website: group.website && group.website !== '' ? group.website : undefined,
  logo: group.logo,
  has_letterhead: group.hasLetterhead === 'yes',
  letterhead:
    group.letterhead && group.letterhead !== '' ? group.letterhead : undefined
});

export const jsonToPhysician = jsonPh => ({
  guid: jsonPh.guid,
  name: jsonPh.full_name,
  email: jsonPh.email,
  phone: jsonPh.phone_number,
  gender: jsonPh.gender,
  dateOfBirth: jsonPh.date_of_birth,
  profilePhoto: jsonPh.profile_photo,
  address: {
    country:
      jsonPh.address && jsonPh.address.country ? jsonPh.address.country : '',
    state: jsonPh.address && jsonPh.address.state ? jsonPh.address.state : '',
    city: jsonPh.address && jsonPh.address.city ? jsonPh.address.city : '',
    street: jsonPh.address && jsonPh.address.street ? jsonPh.address.street : ''
  },
  medicalLicense: jsonPh.medical_license,
  refersTo: jsonPh.refers_to,
  specialty: jsonPh.specialty,
  subSpecialty: jsonPh.sub_speciality,
  education: jsonPh.education,
  primaryFacility: jsonPh.primary_facility,
  secondaryFacility: jsonPh.secondary_facility
});

export const physicianToJson = physician => {
  const jsonObject = {
    full_name: physician.name,
    email: physician.email,
    phone_number: physician.phone
  };
  Object.entries({
    guid: 'guid',
    gender: 'gender',
    dateOfBirth: 'date_of_birth',
    profilePhoto: 'profile_photo',
    medicalLicense: 'medical_license',
    organizationGuid: 'organization_guid',
    refersTo: 'refers_to',
    specialty: 'specialty',
    address: 'address',
    subSpecialty: 'sub_specialty',
    education: 'education',
    primaryFacility: 'primary_facility',
    secondaryFacility: 'secondary_facility,'
  }).forEach(([k, v]) => {
    if (physician[k]) {
      jsonObject[v] = physician[k];
    }
  });
  jsonObject.specialty =
    jsonObject.specialty === 'Custom'
      ? physician.custom_specialty
      : jsonObject.specialty;
  return jsonObject;
};

export const deviceToJSON = values =>
  JSON.stringify({
    port: values.dicomPort,
    serial_number: values.serialNumber,
    type_modality: values.modality,
    manufacturer: values.manufacturer,
    asset_code: values.nickname,
    dicom_ae_title: values.dicomAeTitle,
    in_facility_location: values.inFacilityLocation,
    facility_guid: values.facility,
    organization_guid: values.organizationGuid,
    model: values.model,
    nickname:
      !values.nickname || values.nickname === '' ? 'Device' : values.nickname,
    ip: values.ipAddress,
    connection_type: values.connectionType,
    location: values.location,
    modality_worklist_active: values.modalityWorklistActive,
    status: values.status,
    id: values.id
  });

export const updatedDeviceToJSON = values =>
  JSON.stringify({
    port: values.dicomPort,
    serial_number: values.serialNumber,
    type_modality: values.modality,
    manufacturer: values.manufacturer,
    asset_code: values.nickname,
    dicom_ae_title: values.dicomAeTitle,
    in_facility_location: values.inFacilityLocation,
    model: values.model,
    nickname:
      !values.nickname || values.nickname === '' ? 'Device' : values.nickname,
    ip: values.ipAddress,
    connection_type: values.connectionType,
    location: values.location,
    modality_worklist_active: values.modalityWorklistActive,
    status: values.status,
    id: values.id
  });

export const jsonToDevice = device =>
  Object.entries(device).length > 0
    ? {
      dicomPort: device.port,
      serialNumber: device.serial_number,
      modality: device.type_modality,
      manufacturer: device.manufacturer,
      assetCode: device.nickname,
      dicomAeTitle: device.dicom_ae_title,
      inFacilityLocation: device.in_facility_location,
      facility: device.facility_guid,
      model: device.model,
      nickname: device.nickname,
      ipAddress: device.ip,
      connectionType: device.connection_type,
      location: device.location,
      modalityWorklistActive: device.modality_worklist_active,
      status: device.status,
      id: device.guid,
      organizationGuid: device.organization_guid
    }
    : device;

export const jsonToActiveStudy = jsonGroup => ({
  patientId: checkValue(jsonGroup.patient_id),
  facility: checkValue(jsonGroup.facility_name),
  facilityId: checkValue(jsonGroup.facility_guid),
  sentTo: checkValue(jsonGroup.sent_to),
  time: checkValue(jsonGroup.created_at),
  modality: checkValue(jsonGroup.modality),
  priority: checkValue(jsonGroup.priority)
});

export const jsonToOverview = jsonGroup => ({
  study: {
    created: checkValue(jsonGroup.study.created),
    stored: checkValue(jsonGroup.study.stored)
  },
  report: {
    ready: checkValue(jsonGroup.report.ready),
    draft: checkValue(jsonGroup.report.draft),
    preliminary: checkValue(jsonGroup.report.preliminary),
    final: checkValue(jsonGroup.report.final)
  }
});

export const jsonToRadiologistPerformance = jsonGroup => ({
  radiologistId: checkValue(jsonGroup.radiologist_id),
  radiologistName: checkValue(jsonGroup.radiologist_name),
  totalCount: checkValue(jsonGroup.total_count),
  modalityCount: jsonGroup.modality_count.reduce((acc, cur) => {
    return { ...acc, ...cur };
  }, {})
});

export const jsonToProviderPerformance = jsonGroup => ({
  averageTurnAroundTime: checkValue(jsonGroup.average_turn_around_time),
  date: checkValue(jsonGroup.date)
});

export const jsonToStudyReport = jsonGroup => ({
  modality: checkValue(jsonGroup.modality),
  studies: checkValue(jsonGroup.studies),
  urgent: checkValue(jsonGroup.urgent),
  routine: checkValue(jsonGroup.routine),
  date: checkValue(jsonGroup.date),
  reportingRadiologist: {
    id: checkValue(jsonGroup.reporting_radiologist.id),
    name: checkValue(jsonGroup.reporting_radiologist.name)
  }
});

export const jsonToModalityCount = jsonGroup => ({
  modality: checkValue(jsonGroup.modality),
  routine: checkNumberValue(jsonGroup.routine),
  urgent: checkNumberValue(jsonGroup.urgent),
  total: checkNumberValue(jsonGroup.total_count)
});

export const jsonToStudyCount = jsonGroup => ({
  studyCount: checkNumberValue(jsonGroup.study_count),
  date: checkValue(jsonGroup.time_string)
});

export const jsonToStudyDetails = jsonGroup => ({
  modalityCount: jsonGroup.modality_count.map(i => jsonToModalityCount(i)),
  studyCount: jsonGroup.study_count.map(i => jsonToStudyCount(i))
});

export const jsonToSourceFacilitiesChart = jsonSourceFacilitiesChart => {
  const {
    chart_data: jsonChartData,
    tabular_data: jsonTabularData
  } = jsonSourceFacilitiesChart;

  const chartData = [];
  if (jsonChartData && jsonChartData.length > 0) {
    jsonChartData.forEach(item => {
      item.modality.forEach(modality => {
        chartData.push({
          date: item.date_string,
          value: modality.count,
          category: modality.modality
        });
      });
    });
  }

  const tableData = [];
  if (jsonTabularData && jsonTabularData.length > 0) {
    jsonTabularData.forEach(item => {
      const children = [];
      item.modality_count.forEach(modalityCount => {
        children.push({
          modality: modalityCount.modality,
          routine: modalityCount.routine ? modalityCount.routine : 0,
          urgent: modalityCount.urgent ? modalityCount.urgent : 0
        });
      });
      tableData.push({
        facilityGuid: item.facility_guid,
        modality: 'All',
        routine: item.routine ? item.routine : 0,
        urgent: item.urgent ? item.urgent : 0,
        children
      });
    });
  }

  return { chartData, tableData };
};

export const jsonToSourceReferringPhysiciansChart = source => {
  const { study_count: studyCountData } = source;

  const chartData = [];
  if (studyCountData && studyCountData.length > 0) {
    studyCountData.forEach(item => {
      item.physician_aggs.buckets.forEach(bucket => {
        chartData.push({
          date: moment(item.key_as_string, 'YYYY-MM-DD').format('D MMMM YYYY'),
          value: bucket.doc_count,
          referrerName: bucket.key
        });
      });
    });
  }

  return chartData;
};

export const jsonToSourceReferringFacilitiesChart = source => {
  const { study_count: studyCountData } = source;

  const chartData = [];
  if (studyCountData && studyCountData.length > 0) {
    studyCountData.forEach(item => {
      item.facility_aggs.buckets.forEach(bucket => {
        chartData.push({
          date: moment(item.key_as_string, 'YYYY-MM-DD').format('D MMMM YYYY'),
          value: bucket.doc_count,
          referrerName: bucket.key
        });
      });
    });
  }

  return chartData;
};

export const jsonToSourceReferringFacilitiesTable = source => {
  const { data } = source;

  const tableData = [];
  if (data && data.length > 0) {
    data.forEach(item => {
      const urgent = item.priority_aggs.buckets.find(
        bucket => bucket.key === 'urgent'
      );
      tableData.push({
        referrer: item.key,
        study: item.doc_count,
        urgent: urgent ? urgent.doc_count : 0
      });
    });
  }

  return tableData;
};

// const getModalityCount = (item, modality) => {
//   const modalityAggs = item.facility_name_aggs[0].modality_aggs.find(
//     mod => mod.key === modality
//   );
//   return modalityAggs ? modalityAggs.doc_count : 0;
// };

export const getRefFacilitiesModalityCount = (item, modality) => {
  const modalityAggs = item.facility_name_aggs.find(
    mod => mod.key === modality
  );
  return modalityAggs ? modalityAggs.doc_count : 0;
};

export const jsonToReferringFacilities = source => {
  const { data } = source;

  // Create a count map for all modalities across all facilities
  const modalityCountMap = {};
  data.forEach(item => {
    item.facility_name_aggs.forEach(modality => {
      if (modalityCountMap[modality.key]) {
        modalityCountMap[modality.key] += modality.doc_count;
      } else {
        modalityCountMap[modality.key] = modality.doc_count;
      }
    });
  });

  // Sort the keys based on count and pick top 5
  const topModalities = Object.keys(modalityCountMap)
    .sort((a, b) => modalityCountMap[b] - modalityCountMap[a])
    .slice(0, 5);

  const transformedData = data.map(item => {
    const facilityObj = {
      key: item.key,
      facilityName: item.facility_name,
      other: 0,
      total: item.referall_count
    };
    topModalities.forEach(modality => (facilityObj[modality] = 0)); // Initialize top modalities with 0
    item.facility_name_aggs.forEach(modality => {
      if (topModalities.includes(modality.key)) {
        facilityObj[modality.key] += modality.doc_count;
      } else {
        facilityObj.other += modality.doc_count; // Accumulate 'other' counts
      }
    });
    return facilityObj;
  });

  return { data: transformedData, modalities: topModalities };
};

export const jsonToReferringPhysicians = source => {
  const { data } = source;

  // Create a count map for all modalities across all facilities
  const modalityCountMap = {};
  data.forEach(item => {
    item.facility_name_aggs.forEach(facility => {
      facility.modality_aggs.forEach(modality => {
        if (modalityCountMap[modality.key]) {
          modalityCountMap[modality.key] += modality.doc_count;
        } else {
          modalityCountMap[modality.key] = modality.doc_count;
        }
      });
    });
  });

  // Sort the keys based on count and pick top 5
  const topModalities = Object.keys(modalityCountMap)
    .sort((a, b) => modalityCountMap[b] - modalityCountMap[a])
    .slice(0, 5);

  // Transform the data
  const transformedData = data.map(item => {
    const facilityObj = {
      key: item.key,
      physicianName: item.physcian_name,
      other: 0,
      facility: '',
      total: item.physcian_total_study_count
    };
    topModalities.forEach(modality => (facilityObj[modality] = 0)); // Initialize top modalities with 0
    item.facility_name_aggs.forEach(facility => {
      facilityObj.facility = facility.facility_name;
      facility.modality_aggs.forEach(modality => {
        if (topModalities.includes(modality.key)) {
          facilityObj[modality.key] += modality.doc_count;
        } else {
          facilityObj.other += modality.doc_count; // Accumulate 'other' counts
        }
      });
    });
    return facilityObj;
  });

  return { data: transformedData, modalities: topModalities };
};
