import React, { Component } from 'react';
import { Table as AntdTable } from 'antd';

import ColumnSearch from './ColumnSearch';

import './index.less';

export { ColumnSearch };

const transformKeyFilter = s => {
  switch (s) {
    case 'primaryContact':
      return 'primary_contact';
    case 'studyAccess':
      return 'study_access';
    case 'specialty':
      return 'user_metadata.specialty';
    case 'userType':
      return 'user_metadata.user_type';
    case 'homeFacility':
      return 'user_metadata.home_facility';
    case 'admin':
      return 'app_metadata.admin';
    default:
      return s;
  }
};

const transformValue = (k, v) => {
  switch (k) {
    case 'name':
    case 'email':
    case 'primaryContact':
      if (v.length > 0) {
        return `*${v}*`;
      }
      return '';
    case 'admin':
      return `"${v}"`;
    default:
      return v.split && v.split(' ').length > 1 ? `"${v}"` : v;
  }
};

export default class Table extends Component {
  state = {
    pagination: {
      simple: false,
      position: 'bottom',
      showSizeChanger: true,
      showQuickJumper: false,
      pageSize: 10,
      style: {
        float: 'left',
        padding: '0 16px'
      }
    },
    searchText: '',
    modifications: this.props.modifications // eslint-disable-line react/destructuring-assignment
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.modifications > prevState.modifications) {
      return { modifications: nextProps.modifications };
    }
    return null;
  }

  componentDidMount() {
    this.props.getAction(-1, -1, '', '');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.modifications !== this.state.modifications) {
      this.props.getAction(-1, -1, '', '');
    }
  }

  handleShowSizeChange = (page, pageSize) => {
    this.setState({ pagination: { ...this.state.pagination, pageSize } })
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { getAction, total } = this.props;

    const sort =
      sorter.columnKey && sorter.order
        ? `${sorter.columnKey}:${sorter.order === 'ascent' ? '1' : '-1'}`
        : '';

    let query = '';
    Object.entries(filters)
      .filter(e => e[1].length > 0)
      .forEach(e =>
        typeof e[1] === 'string'
          ? (query += `${transformKeyFilter(e[0])}:${transformValue(
              e[0],
              e[1]
            )}+`)
          : e[1].forEach(
              e1 =>
                (query += `${transformKeyFilter(e[0])}:${transformValue(
                  e[0],
                  e1
                )}+`)
            )
      );
    query = query.slice(0, -1);

    getAction(pagination.pageSize, pagination.current - 1, sort, query);
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        current: pagination.current,
        total
      }
    }));
  };

  render() {
    const { data, loading, rowKey, columns, hidePagination } = this.props;
    const { searchText } = this.state;

      // Setup the new pagination config in render, not in state
    const pagination = {
      ...this.state.pagination,
      onShowSizeChange: this.handleShowSizeChange, // this will now correctly reference the instance method
    };

    const cols = columns.map(col => {
      // eslint-disable-line  react/destructuring-assignment
      if (col.columnSearch) {
        col = {
          ...col,
          ...ColumnSearch(col.dataIndex, this.searchInput, searchText, col.searchPlaceholder)
        };
      }
      delete col.columnSearch;
      return col;
    });
    console.log("this.props.className", this.props.className);
    

    return (
      <div className='common-table'>
        <AntdTable
          className={this.props.className}
          columns={cols}
          rowKey={rowKey}
          dataSource={data}
          pagination={hidePagination ? false : pagination}
          loading={loading}
          // onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
