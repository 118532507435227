import React from 'react';
import { Upload, Button, Icon } from 'antd';

class UploadField extends React.Component {
    handleChange = ({ file, fileList }) => {
        const { input } = this.props;
        let fileItem = fileList[fileList.length - 1];
        let fileObj = {
            "preview": URL.createObjectURL(fileItem.originFileObj),
            "name": fileItem.name,
            "size": fileItem.size,
            "type": fileItem.type,
            file: fileItem.originFileObj
        }
        input.onChange({ ...fileObj });
    };

    render() {
        const { input, meta, ...rest } = this.props;
        return (
            <div className='upload-btn-container'>
                <Upload
                    {...rest}
                    fileList={input.value ? [input.value] : []}
                    onChange={this.handleChange}
                    m
                    beforeUpload={() => false}
                    onRemove={() => input.onChange(null)}
                >
                    <Button size='large' block>
                        <Icon type="upload" /> Upload
                    </Button>
                </Upload>
                {meta.touched && meta.error && <span className="error-text">{meta.error}</span>}
            </div>
        );
    }
}

export default UploadField;
