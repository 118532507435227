// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Divider, Dropdown, Icon, Menu, Row, Switch, Tag, Table } from 'antd';
// import Table from '../../components/Table';
import { t } from 'i18next';
import {
  getInvitationsListRequest,
  postResendInvitationRequest,
  deleteRevokeInvitationRequest
} from '../../store/actions';
import { upperFirst } from 'lodash';
import ViewInvitedUser from './ViewInvitedUser';



const TableInvites = ({
  total,
  loading,
  invitations,
  modifications,
  resendInvitation,
  getInvitationsList,
  deleteRevokeInvitation
}) => {
  useEffect(() => {
    getInvitationsList();
  }, []);

  const SendIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5534 6.79451L0.483083 0.241384C0.429958 0.214821 0.369021 0.208571 0.311208 0.222634C0.178395 0.255446 0.0955829 0.389821 0.128395 0.524196L1.47527 6.02732C1.49558 6.11013 1.55652 6.17732 1.63777 6.20388L3.94558 6.99607L1.63933 7.78826C1.55808 7.81638 1.49715 7.88201 1.4784 7.96482L0.128395 13.4758C0.114333 13.5336 0.120583 13.5945 0.147145 13.6461C0.208083 13.7695 0.358083 13.8195 0.483083 13.7586L13.5534 7.24295C13.6018 7.21951 13.6409 7.17888 13.6659 7.13201C13.7268 7.00701 13.6768 6.85701 13.5534 6.79451ZM1.66902 11.9101L2.45496 8.69763L7.06746 7.11482C7.1034 7.10232 7.13308 7.0742 7.14558 7.0367C7.16746 6.97107 7.13308 6.90076 7.06746 6.87732L2.45496 5.29607L1.67215 2.09607L11.4846 7.01638L1.66902 11.9101Z" fill="#666666" fill-opacity="0.85" />
  </svg>
  const actionHandle = (invitationGuid, action) => {
    if (action === 'resend') {
      resendInvitation(invitationGuid);
    } else if (action === 'revoke') {
      deleteRevokeInvitation(invitationGuid);
    }
  };

  const columns = [
    {
      title: t('dashboard.table.name'),
      dataIndex: 'name',
      sorter: (a, b) =>
        (a.name ? a.name : '')
          .toString()
          .toLowerCase()
          .localeCompare((b.name ? b.name : '').toString().toLowerCase()),
      columnSearch: true,
      render: (text, record) => text
    },
    {
      title: t('user_administration.field.role'),
      dataIndex: 'user_type',
      render: (text, record) => {
        return record.user_metadata && upperFirst(record.user_metadata.user_type)
      }
    },
    {
      title: t('user_administration.field.home_facility'),
      dataIndex: 'home_facility',
      render: (text, record) => {
        return record.user_metadata && upperFirst(record.user_metadata.home_facility)
      }
    },
    {
      title: t('user_administration.field.invitation_status'),
      dataIndex: 'status',
      render: (text) => {
        return <Tag color="orange">{t('user_administration.field.pending')}</Tag>;
      }
    },
    // {
    //   title: 'Admin Access',
    //   dataIndex: 'access',
    //   render: (text, record) => {
    //     return (
    //       <div>
    //         <Switch checked={record.app_metadata.admin == "1"} onChange={() => {
    //           console.log("changed=-=--=--=--=--=---=-=-=");
    //         }} />
    //       </div>
    //     )
    //   }
    // },
    {
      title: t('dashboard.table.action'),
      key: 'invitation_guid',
      render: (text, record) => {
        return (
          <Row type='flex' align='middle'>
            <Col>
              <ViewInvitedUser data={record} />
            </Col>
            <Col>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => actionHandle(text.invitation_guid, 'resend')}
                    >
                      <Icon component={SendIcon} />{t('dashboard.table.revoke_invitation')}
                    </Menu.Item>
                    <Divider style={{ margin: "10px 0 0 0" }} />
                    <Menu.Item
                      onClick={() => actionHandle(text.invitation_guid, 'revoke')}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', color: "#FF4D4F" }}><Icon type="info-circle" style={{ marginRight: 10 }} /> {t('dashboard.table.revoke_invitation')}</div>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type='link'>{t('button.menu')}</Button>
              </Dropdown>
            </Col>
          </Row>
        )
      }
    }
  ];

  const rowKey = invite => invite.invitation_guid;

  return (
    <div className='invite-table'>
      <Table
        columns={columns}
        total={total}
        dataSource={invitations}
        size='small'
        // data={invitations}
        pagination={{
          position: "bottom",
          size: "large"
        }}
        modifications={modifications}
        rowKey={rowKey}
        loading={loading}
        getAction={() => null}
      />
    </div>
  );
};

export default connect(
  state => ({
    invitations: state.groupInvitations
  }),
  {
    getInvitationsList: getInvitationsListRequest,
    resendInvitation: postResendInvitationRequest,
    deleteRevokeInvitation: deleteRevokeInvitationRequest
  }
)(TableInvites);
