import React, { useState } from 'react';
import { Typography, Row, Col, Breadcrumb, Tabs } from 'antd';
import { t } from 'i18next';
import AddUser from './AddUser';
import TableUser from './TableUser';
import TableInvites from './TableInvites';
import './index.less';

const { Title } = Typography;
const { TabPane } = Tabs;
function Users() {
  const [currentTab, setCurrentTab] = useState("1");

  return (
    <div className='user-management-index'>
      <div className='user-management-header'>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item><Title level={3}>{t('user_administration.title')}</Title></Breadcrumb.Item>
            </Breadcrumb>
            <p>{t('user_administration.add_organisation_rights')}</p>
          </Col>
          <Col>
            <AddUser />
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <Tabs defaultActiveKey={currentTab} onChange={(value) => { console.log(value) }}>
            <TabPane tab="All Users" key="1">
              <TableUser />
            </TabPane>
            <TabPane tab="Pending Invitations" key="2">
              <TableInvites />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {/* <Row style={{ marginBottom: '20px' }}>
        <TableInvites />
      </Row>
      <div className='user-management-header'>
        <Row type='flex' justify='space-between' align='middle'>
          <Col>
            <Title level={3} style={{ color: '#666', fontWeight: 400 }}>
              {t('user_administration.current_users')}
            </Title>
          </Col>
        </Row>
      </div>
      <Row>
        <TableUser />
      </Row> */}
    </div>
  );
}

export default Users;
