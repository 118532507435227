import { t } from 'i18next';
import moment from 'moment';

export const dateFilters = {
  TODAY: moment().format('l'),
  THIS_WEEK: moment()
    .subtract(7, 'days')
    .format('l'),
  THIS_MONTH: moment()
    .subtract(30, 'days')
    .format('l'),
  LAST_MONTH: moment()
    .subtract(60, 'days')
    .format('l')
};

export const studyPriorities = [
  'routine',
  'expedite',
  'priority',
  'urgent',
  'emergent'
];
export const labeledStudyPriorities = [
  'expedite',
  'priority',
  'urgent', 
  'emergent'
];

const { TODAY, THIS_WEEK, THIS_MONTH, LAST_MONTH } = dateFilters;

export const dateFilterStrings = {
  TODAY: 'today',
  THIS_WEEK: 'week',
  CUSTOM: 'custom'
};

export const dateOptions = [
  { value: TODAY, label: 'dashboard.today' },
  {
    value: THIS_WEEK,
    label: 'dashboard.this_week'
  },
  {
    value: THIS_MONTH,
    label: 'dashboard.this_month'
  },
  {
    value: LAST_MONTH,
    label: 'dashboard.last_month'
  }
];

export const facilityOptions = [
  { value: 'ALL', label: 'All Source Facilities' },
  { value: 'Source Facility ABC', label: 'Source Facility ABC' }
];

export const sentToOptions = [
  { value: 'ALL', label: 'All Sent To' },
  { value: 'My Facility/Provider', label: 'My Facility/Provider' }
];

export const modalityOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'CT', label: 'CT' },
  { value: 'MR', label: 'MR' },
  { value: 'DR', label: 'DR' },
  { value: 'US', label: 'US' },
  { value: 'NM', label: 'NM' },
  { value: 'DS', label: 'DS' },
  { value: 'OT', label: 'OT' },
  { value: 'CR', label: 'CR' },
  { value: 'OP', label: 'OP' }
];

export const fullModalityOptions = [
  { label: 'Computed Radiography (CR)', value: 'CR' },
  { label: 'Computed Tomography (CT)', value: 'CT' },
  { label: 'Digital Radiography (DX)', value: 'DX' },
  { label: 'Electrocardiography (ECG)', value: 'ECG' },
  { label: 'Magnetic Resonance (MR)', value: 'MR' },
  { label: 'Mammography (MG)', value: 'MG' },
  { label: 'Nuclear Medicine (NM)', value: 'NM' },
  { label: 'Ophthalmic Photography (OP)', value: 'OP' },
  { label: 'Other (OT)', value: 'OT' },
  { label: 'Positron emission tomography (PET)', value: 'PT' },
  { label: 'Radio Fluoroscopy (RF)', value: 'RF' },
  { label: 'X-Ray Angiography (XA)', value: 'XA' },
  { label: 'Ultrasound (US)', value: 'US' }
];

export const reportingOptions = [
  { value: 'all', label: 'All Reporting Radiologists' }
];

export const viewOptions = [
  { value: 'RVU', label: 'RVU' },
  { value: 'COUNT', label: 'Count' }
];

export const bodyPartOptions = [
  { value: 'all', label: 'All' },
  { value: 'brain', label: 'Brain' },
  { value: 'chest', label: 'Chest' },
  { value: 'wrist', label: 'Wrist' }
];

export const urgencyOptions = [
  { value: 'routine', label: 'Routine' },
  { value: 'urgent', label: 'Urgent' }
];

export const connectionTypeOptions = [
  { value: 'AI Appliance', label: 'AI Appliance' },
  { value: 'Imaging Modality', label: 'Imaging Modality' },
  { value: 'Viewing Workstation', label: 'Viewing Workstation' },
  { value: 'RIS/PACS or VNA', label: 'RIS/PACS or VNA' },
  { value: 'Other', label: 'Other' }
];

export const connectionTypeOptionsWithDicomInfo = [
  'AI Appliance',
  'Imaging Modality',
  'Viewing Workstation',
  'RIS/PACS or VNA',
  'Other'
];

export const manufacturerOptions = [
  { value: 'GE', label: 'GE' },
  { value: 'Siemens', label: 'Philips' },
  { value: 'AGFA', label: 'AGFA' },
  { value: 'Fuji', label: 'Fuji' },
  { value: 'Carestream', label: 'Carestream' },
  { value: 'Olympus', label: 'Olympus' },
  { value: 'AlemHealth', label: 'AlemHealth' },
  { value: 'TopCon', label: 'TopCon' }
];

// # MODALITIES
// # CR    Computed Radiography √
// # CT    Computed Tomography √
// # DX    Digital Radiography √
// # ECG    Electrocardiography √
// # MG    Mammography √
// # MR    Magnetic Resonance √
// # NM    Nuclear Medicine √
// # OP    Ophthalmic Photography √
// # OT    Other
// # PT    Positron emission tomography (PET) √
// # RF    Radio Fluoroscopy √
// # XA    X-Ray Angiography √
// # US    Ultrasound √
// # XA    X-Ray Angiography

export const signatureFontOptions = [
  { value: 'Inter', text: 'Signature' },
  { value: 'Signatura monoline script', text: 'Signature' },
  { value: 'Dancing script', text: 'Signature' },
  { value: 'Sacramento', text: 'Signature' },
  { value: 'Kaushan script', text: 'Signature' },  
]; 

// export const qualificationOptions = [
//   { label: "Musculoskeletal Imaging", value: "Musculoskeletal Imaging" },
//   { label: "Neuroradiology", value: "Neuroradiology" },
//   { label: "Nuclear Medicine", value: "Nuclear Medicine" },
//   { label: "Pediatric Radiology", value: "Pediatric Radiology" },
//   { label: "Thoracic Imaging", value: "Thoracic Imaging" }
// ];

export const qualificationOptions = [
  "Musculoskeletal Imaging",
  "Neuroradiology",
  "Nuclear Medicine",
  "Pediatric Radiology",
  "Thoracic Imaging"
];