import React, { useState } from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { t } from 'i18next';
import { connect } from 'react-redux';
import userValidation from './userValidation';
import BaseUserForm from './BaseUserForm';
import { InputSelectField } from '../../../components/FormParts';
import './BaseUserForm.less';
import BasicSelectField from '../../../common/form-helpers/BasicSelectField';

const ADD_USER_FORM_NAME = 'createUserForm';

type Props = {
  visible: boolean,
  onCancel: () => void,
  loading: boolean,
  // Values passed by reduxForm
  handleSubmit: any => void,
  pristine: boolean,
  submitting: boolean
};

export default connect(
  state => ({
    initialValues: state.initialForm.addUser,
    currentValues: getFormValues(ADD_USER_FORM_NAME)(state)
  }),
  {}
)(
  reduxForm({
    form: ADD_USER_FORM_NAME,
    validate: userValidation
  })(
    ({
      visible,
      onCancel,
      loading,
      handleSubmit,
      pristine,
      submitting,
      onClickAddSignature,
      signatureSettings,
      azureBlobSAS,
      onClickDeleteSignature,
      currentValues
    }: Props) => {
      const [inviteUserWithOpen, setInviteUserWithOpen] = useState(false);
      
      return (
        <BaseUserForm
          loading={loading}
          onCancel={onCancel}
          visible={visible}
          title={t('button.add_new_user')}
          onClickAddSignature={onClickAddSignature}
          azureBlobSAS={azureBlobSAS}
          okText='Add'
          selectedFacilities={[]}
          signatureSettings={signatureSettings}
          onClickDeleteSignature={onClickDeleteSignature}
        >
          <Row
            type='flex'
            className='user-form__actions'
            gutter={10}
            justify='space-around'
            align='bottom'
          >
            <Col span={8}>
              <Field
                name='inviteUserWith'
                label='Invite user with'
                style={{ width: '100%' }}
                component={BasicSelectField}
                options={[
                  {
                    text: (
                      <Tooltip
                        placement='right'
                        title={t('user_administration.full_allows_user_the_medical_images')}
                      >
                        {t('user_administration.full_access')}
                      </Tooltip>
                    ),
                    value: 'Full Access'
                  },
                  {
                    text: (
                      <Tooltip
                        placement='right'
                        title={t('user_administration.limited_access_allows_user')}
                      >
                        {t('user_administration.limited_access')}
                      </Tooltip>
                    ),
                    value: 'Limited Access'
                  }]}
              />
            </Col>
            <Col span={16} style={{ textAlign: 'center' }}>
              <div>
                <Button
                  disabled={pristine || submitting || (currentValues && !currentValues.inviteUserWith)}
                  type='primary'
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {t('button.invite')}
                </Button>
                <Button onClick={onCancel}>{t('button.cancel')}</Button>
              </div>
            </Col>
          </Row>
        </BaseUserForm>
      );
    }
  )
);
