import React, { Component, Fragment } from 'react';
import { Menu, Dropdown, Icon, Divider, Modal } from 'antd';
import { t } from 'i18next';
import {
  confirmDeletion,
  confirmResetPassword
} from '../../../components/confirmations';
import EditUser from '../EditUser';
import type { User } from '../../../schemas';
import UserSignatureForm from '../forms/UserSignatureForm';
import { getSharedAccessSignatureForAzureBlobAPICall } from '../../../api';

const { Item } = Menu;
const { confirm } = Modal;

type Props = {
  record: User,
  getAction: () => void,
  deleteAction: (string, string) => void,
  resetPassword: string => void
};

type State = {
  editUser: boolean
};

export default class UserTableActions extends Component<Props, State> {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    editUser: false,
    signatureModal: false,
    signatureData: null,
    azureBlobSAS: null
  };

  handleCancelEdit = () => {
    this.setState({ editUser: false, signatureData: null });
    this.props.removeUserAction()
    this.props.reset("userSignatureForm")
  };

  componentDidMount() {
    !this.state.azureBlobSAS && this.getSASForAzureBlob();
  }

  handleMenuClick = e => {
    const { deleteAction, resetPassword, getAction, record } = this.props;
    const { props } = e.item;
    switch (e.key) {
      case '1':
        getAction(record.id);
        this.setState({ editUser: true });
        break;
      case '2':
        confirmResetPassword(resetPassword, props.email);
        break;
      case '3':
        confirmDeletion(deleteAction, props.id, props.name, 'user');
        break;
      case '4':
        e.item.setState({ visible: false });
        break;
      default:
        break;
    }
  };

  onClickAddSignature = () => {
    this.setState({ signatureModal: true });
  };

  getSASForAzureBlob = async () => {
    const { profile } = this.props
    const { responseOk, responseJSON } = await getSharedAccessSignatureForAzureBlobAPICall(profile)

    if (responseOk) {
      const { azure_blob_sas: azureBlobSAS } = responseJSON
      this.setState({ azureBlobSAS })
    }
  }

  callbackFunction = (status) => {
    status && this.setState({ signatureModal: false });
  }
  signatureUploadHandler = (data) => {
    const user = this.props.user;
    const { signature, name, title, credentials, phone_number, qualifications, auto_generated, font_name, signature_name, digitally_signed, signature_text, reporting_date_format, display_phone_number, designation, signatureNew } = data
    let requestBodyData = {
      ...user,
      user_metadata: {
        ...user.user_metadata,
        name: name || "",
        title: title || "",
        credentials: credentials || "",
        phone_number: phone_number || "",
        signature_settings: {
          auto_generated: auto_generated,
          font_name: font_name || "",
          // signature_name: signature_name || "",
          digitally_signed: digitally_signed || false,
          designation: designation || "",
          display_phone_number: display_phone_number || false,
          reporting_date_format: reporting_date_format || "",
          qualifications: qualifications || "",
          signature_text: signature_text || ""
        }
      },
    }
    delete requestBodyData.userMetadata
    delete requestBodyData.appMetadata
    if (auto_generated) {
      this.props.postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData, this.callbackFunction)
    } else {
      if (signature && !signature.file) {
        this.props.postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData, this.callbackFunction)
      } else {
        this.props.postUserSignatureUploadRequest(signature.file, false, user.id, requestBodyData, this.callbackFunction)
      }
    }
  }

  initialValuesSignature(isForm) {
    const { user } = this.props
    if (user && Object.keys(user).length > 0) {
      const { userMetadata: { name, credentials, phoneNumber, title, userType, signature, signature_settings } } = user || {}
      if (signature_settings && Object.keys(signature_settings).length > 0) {
        const { auto_generated, digitally_signed, font_name, qualifications, signature_name, signature_text, designation, reporting_date_format, display_phone_number } = signature_settings
        return {
          title: title,
          name: name,
          credentials: credentials,
          phone_number: phoneNumber,
          designation: designation,
          auto_generated: auto_generated,
          signatureNew: signature,
          signature: signature,
          digitally_signed: digitally_signed,
          font_name: font_name,
          reporting_date_format: reporting_date_format,
          qualifications: qualifications || "",
          signature_name: signature_name,
          signature_text: signature_text,
          display_phone_number: display_phone_number
        }
      } else if (isForm) {
        return {
          auto_generated: true
        }
      } else {
        return {}
      }
    }
  }

  onClickDeleteSignature = (id) => {
    const user = this.props.user;
    let requestBodyData = {
      ...user,
      user_metadata: {
        ...user.user_metadata,
        signature_settings: {}
      }
    }
    delete requestBodyData.userMetadata
    delete requestBodyData.appMetadata
    confirm({
      title: t('user_administration.delete_signature'),
      content:  t('user_administration.delete_signature_confirm'),
      okText:  t('button.delete'),
      okType: 'danger',
      icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
      cancelText:  t('button.cancel'),
      onOk: () => {
        this.props.postUserSignatureUploadRequest(undefined, true, user.id, requestBodyData)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  render() {
    const { record, user } = this.props;
    const { editUser } = this.state;
    return (
      <Fragment>
        {editUser && <EditUser signatureSetting={this.state.signatureData} azureBlobSAS={this.state.azureBlobSAS} onClickAddSignature={this.onClickAddSignature} onClickDeleteSignature={this.onClickDeleteSignature} visible={true} onCancel={this.handleCancelEdit} />}
        {this.state.signatureModal && <UserSignatureForm user={user} azureBlobSAS={this.state.azureBlobSAS} initialValues={this.initialValuesSignature(true)} onClose={() => this.setState({ signatureModal: false })} onSubmit={this.signatureUploadHandler} />}
        <Dropdown
          overlay={
            <Menu onClick={this.handleMenuClick}>
              <Item key='1' id={record.id}>
                <div><Icon type="edit" style={{ marginRight: 10 }} /> {t('dashboard.edit_user')}</div>
              </Item>
              <Item key='2' email={record.email}>
                <div><Icon type="key" style={{ marginRight: 10 }} />  {t('button.reset_password')}</div>
              </Item>
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Item
                key='3'
                id={record.id}
                name={record.name}
                disabled={record.appMetadata.main === '1'}
              >
                <div style={{ color: '#FF4D4F' }}><Icon type="delete" style={{ marginRight: 10 }} /> {t('button.delete_user')}</div>
              </Item>
            </Menu>
          }
          trigger={['click']}
        >
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            {t('button.menu')}
          </a>
        </Dropdown>
      </Fragment>
    );
  }
}
