/* eslint-disable no-unused-vars */
// @flow

import * as React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Drawer } from 'antd';
import { enquireScreen, unenquireScreen } from 'enquire-js';
import { t } from 'i18next';
import { AppLayout } from '../components';

import { config, pathMatchRegexp } from '../utils';
import Error from '../pages/404';
import { routeList, getRoutes } from '../utils/routes';
import {
  getCountryListRequest,
  getExternalFacilitiesAction,
  getInternalFacilitiesAction,
  getReferringFacilitiesListAction,
  handleCollapseChange
} from '../store/actions';

import './PrimaryLayout.less';

const { Content, Footer } = Layout;
const { Header, Bread, Sider } = AppLayout;

export default withRouter(
  connect(
    state => ({
      user: state.profile,
      collapsed: state.aspect.collapsed,
      token: state.profile.tokem
    }),
    {
      onCollapseChange: handleCollapseChange,
      getCountryList: getCountryListRequest,
      getInternalFacilities: getInternalFacilitiesAction,
      getExternalFacilities: getExternalFacilitiesAction,
      getReferringFacilities: getReferringFacilitiesListAction
    }
  )(
    class PrimaryLayout extends React.PureComponent {
      state = { isMobile: false };

      enquireHandler;

      componentDidMount() {
        const {
          getInternalFacilities,
          getExternalFacilities,
          getReferringFacilities,
          getCountryList,
          token,
          user
        } = this.props;

        const {
          userMetadata: { userType }
        } = user;

        if (userType && userType.toLowerCase() === 'independent radiologist') {
          window.location.href = 'https://form.typeform.com/to/Zd5YxR5r';
        }

        this.enquireHandler = enquireScreen(mobile => {
          const { isMobile } = this.state;
          if (isMobile !== mobile) {
            this.setState({
              isMobile: mobile
            });
          }
        });
        getInternalFacilities();
        getExternalFacilities();
        getReferringFacilities();
        getCountryList();
      }

      componentWillUnmount() {
        unenquireScreen(this.enquireHandler);
      }

      // eslint-disable-next-line react/destructuring-assignment
      onCollapseChange = collapsed => this.props.onCollapseChange(collapsed);

      render() {
        const {
          user,
          onCollapseChange,
          collapsed,
          location,
          children,
          noTitle
        } = this.props;
        const { notifications } = user;
        const {
          appMetadata: { admin }
        } = user;
        const permissions = getRoutes(admin);

        const { isMobile } = this.state;

        // Find a route that matches the pathname.
        const currentRoute = routeList.find(
          _ => _.route && pathMatchRegexp(_.route, location.pathname)
        );

        // Query whether you have permission to enter this page
        const hasPermission = currentRoute
          ? permissions.includes(currentRoute.id)
          : false;

        // MenuParentId is equal to -1 is not a available menu.
        const menus = routeList.filter(route => route.menuParentId !== '-1');
        // console.log("okkkkkkkk", menus)

        const newMenu = menus.map(item => {
          return {
            ...item,
            name: t(`side_bar.${item.name}`)
          };
        });
        console.log('okkkkknewMenu', newMenu);

        const headerProps = {
          menus: newMenu,
          collapsed,
          notifications,
          onCollapseChange,
          avatar: user.avatar,
          username: user.name,
          fixed: config.interface.fixedHeader
        };

        const siderProps = {
          menus: newMenu,
          isMobile,
          collapsed,
          onCollapseChange
        };

        return (
          <div id='primary-layout'>
            <Layout>
              <Header {...headerProps} />
              <Layout style={{ marginTop: 60 }}>
                {isMobile ? (
                  <Drawer
                    maskClosable
                    closable={false}
                    onClose={onCollapseChange.bind(this, !collapsed)} // eslint-disable-line react/jsx-no-bind
                    visible={!collapsed}
                    placement='left'
                    width={200}
                    style={{
                      padding: 0,
                      height: '100vh'
                    }}
                  >
                    <Sider {...siderProps} collapsed={false} />
                  </Drawer>
                ) : (
                  <Sider {...siderProps} />
                )}
                <div className='container' id='primaryLayout'>
                  <Content className={`content ${location.pathname.includes('user')? "white-background":""}`}>
                    {noTitle ? null : <Bread routeList={routeList} />}
                    {hasPermission ? children : <Error />}
                  </Content>
                  <Footer style={{ textAlign: 'center', marginTop: '-125px' }}>
                    Copyright © AlemHealth {moment().year()}
                    <div style={{ fontSize: '12px' }}>
                      version {process.env.REACT_APP_VERSION}
                    </div>
                  </Footer>
                </div>
              </Layout>
            </Layout>
          </div>
        );
      }
    }
  )
);
