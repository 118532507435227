import { Form, Select } from "antd";
import React from "react";

const BasicSelectField = ({
  input,
  meta,
  options,
  hasFeedback,
  label,
  isMultiple = false, 
  showSearch,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;

  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasError}
      help={hasError && meta.error}
    >
      <Select
        {...input}
        {...rest}
        mode={isMultiple ? 'multiple' : undefined} // Conditionally add multiple mode
        showSearch={showSearch ? true : false} // Enable search functionality
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)} // Manually handle blur event
        value={input.value} // Set the value from redux-form's state
        filterOption={(inputValue, option) =>
          option.children.toLowerCase().includes(inputValue.toLowerCase())
        } // Optional: customize search filtering
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default BasicSelectField;
