// @flow
import React, { Component } from 'react';
import { Button, Icon, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { t } from 'i18next';
import AddUserForm from './forms/AddUserForm';

import {
  addUserAction,
  getInternalFacilitiesAction,
  loadAddUserForm
} from '../../store/actions';
import { createUserValuesToUser, editUserValuesToUser } from '../../api/transformers';

import type { Facility, ReduxState, User } from '../../schemas';
import { getSharedAccessSignatureForAzureBlobAPICall } from '../../api';
import UserSignatureForm from './forms/UserSignatureForm';
import { reset } from "redux-form"

const { confirm } = Modal;

type Props = {
  facilities: Array<Facility>,
  loading: boolean,
  loadingFacilities: boolean,
  affiliatedGroup: string,
  modifications: number,
  createAction: User => void,
  loadFacilities: () => void
};

type State = {
  visible: boolean,
  modifications: number,
  close: boolean
};

export default connect(
  (state: ReduxState) => ({
    form: state.form.createUserForm,
    loading: state.loading.createUser,
    loadingFacilities: state.loading.facilities,
    modifications: state.users.modifications,
    affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
    facilities: state.facilities.internalArray
  }),
  {
    createAction: addUserAction,
    loadForm: loadAddUserForm,
    loadFacilities: getInternalFacilitiesAction,
    reset
  }
)(
  class AddUser extends Component<Props, State> {
    state = {
      visible: false,
      modifications: this.props.modifications,
      close: false,
      signatureModal: false,
      azureBlobSAS: null,
      signatureData: null
    };

    // We check if the number of created users is incremented (i.e. if the user creation has succeed,
    // and use this information to update the state.
    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.modifications > prevState.modifications) {
        return { modifications: nextProps.modifications, visible: false };
      }
      return null;
    }

    componentDidMount() {
      const { loadFacilities } = this.props;
      loadFacilities();
    }

    showModal = () => {
      this.setState({ visible: true });
    };

    handleCancel = () => {
      this.setState({ visible: false });
      this.props.reset("userSignatureForm")
    };

    handleCreate = async values => {
      const { signatureData } = this.state
      const { createAction, affiliatedGroup, facilities } = this.props;
      if (signatureData) {
        if (signatureData.auto_generated) {
          const data = createUserValuesToUser(values, affiliatedGroup, facilities, signatureData);
          createAction(data);
        } else {
          if (signatureData.signature && signatureData.signature.file) {
            const data = createUserValuesToUser(values, affiliatedGroup, facilities, signatureData);
            createAction(data, signatureData.signature.file);
            this.props.reset("userSignatureForm")
          } else {
            const data = createUserValuesToUser(values, affiliatedGroup, facilities, signatureData);
            createAction(data);
            this.props.reset("userSignatureForm")
          }
        }
      } else {
        const data = createUserValuesToUser(values, affiliatedGroup, facilities, undefined);
        createAction(data);
      }
    };

    getSASForAzureBlob = async () => {
      const { profile } = this.props
      const { responseOk, responseJSON } = await getSharedAccessSignatureForAzureBlobAPICall(profile)

      if (responseOk) {
        const { azure_blob_sas: azureBlobSAS } = responseJSON
        this.setState({ azureBlobSAS })
      }
    }

    initialValuesSignature(isForm) {
      if (isForm) {
        return {
          auto_generated: true,
          font_name: "Inter"
        };
      }
      return {}
    }

    signatureUploadHandler = (data) => {
      this.setState({ signatureData: data, signatureModal: false });
    }

    onClickDeleteSignature = () => {
      confirm({
        title: t('user_administration.delete_signature'),
        content:  t('user_administration.delete_signature_confirm'),
        okText:  t('button.delete'),
        okType: 'danger',
        icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
        cancelText:  t('button.cancel'),
        onOk: () => {
          this.setState({ signatureData: null })
          this.props.reset("userSignatureForm")
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    render() {
      const { loading, loadingFacilities } = this.props;
      const { visible, signatureData, azureBlobSAS } = this.state;

      return (
        <div>
          <Button type='primary' onClick={this.showModal}>
            {t('button.add_new_user')}
          </Button>
          {visible ? ( // We have to use this trick to force form destruction
            loadingFacilities ? (
              <Modal
                visible={visible}
                title={t('user_administration.new_internal_radiologist')}
                okText='Add'
                footer={null}

                onCancel={this.handleCancel}
                wrapClassName='lateral-modal'
              >
                <div style={{ textAlign: 'center' }}>
                  <Spin size='large' />
                </div>
              </Modal>
            ) : (
              <AddUserForm
                visible={visible}
                loading={loading}
                onCancel={this.handleCancel}
                onSubmit={this.handleCreate}
                azureBlobSAS={azureBlobSAS}
                signatureSettings={signatureData}
                onClickAddSignature={() => this.setState({ signatureModal: true })}
                onClickDeleteSignature={this.onClickDeleteSignature}
              />
            )
          ) : null}
          {this.state.signatureModal && <UserSignatureForm user={{}} azureBlobSAS={this.state.azureBlobSAS} initialValues={this.initialValuesSignature(true)} onClose={() => this.setState({ signatureModal: false })} onSubmit={this.signatureUploadHandler} />}
        </div>
      );
    }
  }
);
