/* eslint-disable react/no-children-prop */

import React from 'react';
import { AutoComplete, Form, Input } from 'antd';

const FormItem = Form.Item;

const InputAutoCompleteField = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      <AutoComplete {...input} {...rest} children={children} />
    </FormItem>
  );
};

export default InputAutoCompleteField;
