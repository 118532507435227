import { Button, Col, Modal, Row, Spin } from "antd";
import { t } from "i18next";
import React, { useState } from "react";

const ViewInvitedUser = ({ data = {} }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (Object.entries(data).length === 0) {
    return (
      <div>
        <Button type='link' onClick={showModal}>{t('user_administration.field.view')}</Button>
        <Modal
          visible={this.state.visible}
          title={t('dashboard.user_details')}
          okText='Edit'
          footer={null}
          onCancel={this.handleCancel}
        >
          <div style={{ textAlign: 'center' }}>
            <Spin size='large' />
          </div>
        </Modal>
      </div>
    );
  }

  return <div>
    <Button type='link' onClick={showModal}>{t('user_administration.field.view')}</Button>
    <Modal
      title={t('dashboard.user_details')}
      okText='Edit'
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose
      footer={
        <Row type='flex' justify='end' align='middle'>
          <Col style={{ textAlign: 'left' }}>
            <Button onClick={handleCancel}>{t('button.close')}</Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={32}>
        <Col className='gutter-row' span={12}>
          <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
            <Col style={{ width: 50 }}>
              <p>{t('user_administration.field.title')}</p>
              <p style={{ color: 'black' }}>{data.user_metadata.title}</p>
            </Col>
            <Col>
              <p>{t('user_administration.field.name')}</p>
              <p style={{ color: 'black' }}>{data.name}</p>
            </Col>
          </Row>
        </Col>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.credentials')}</p>
          <p style={{ color: 'black' }}>
            {data.user_metadata.credentials}
          </p>
        </Col>
      </Row>
      <br />
      {data.user_metadata.specialty ? (
        <div>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <p>{t('user_administration.field.role')}</p>
              <p style={{ color: 'black' }}>
                {data.user_metadata.user_type}
              </p>
            </Col>
            <Col className='gutter-row' span={12}>
              <p>{t('user_administration.field.specialty')}</p>
              <p style={{ color: 'black' }}>
                {typeof data.user_metadata.specialty === 'object'
                  ? data.user_metadata.specialty.join(', ')
                  : data.user_metadata.specialty}
              </p>
            </Col>
          </Row>
          <br />
        </div>
      ) : null}
      <Row gutter={32}>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.email_adress')}</p>
          <p style={{ color: 'black' }}>{data.invitee_email}</p>
        </Col>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.mobile_number')}</p>
          <p style={{ color: 'black' }}>
            {data.user_metadata.phone_number || '—'}
          </p>
        </Col>
      </Row>
      <br />
      <Row gutter={32}>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.home_facility')}</p>
          <p style={{ color: 'black' }}>
            {data.user_metadata.home_facility || '—'}
          </p>
        </Col>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.admin_access')}</p>
          <p style={{ color: 'black' }}>
            {(data.app_metadata.admin === '1' ? 'Yes' : '') +
              (data.app_metadata.main === '1' ? ' (Main)' : '')}
          </p>
        </Col>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.delete_access')}</p>
          <p style={{ color: 'black' }}>
            {data.app_metadata.allowed_to_delete_study === '1'
              ? 'Yes'
              : 'No'}
          </p>
        </Col>
        <Col className='gutter-row' span={12}>
          <p>{t('user_administration.field.available_assignment')}</p>
          <p style={{ color: 'black' }}>
            {data.app_metadata.available_for_assignment === '1'
              ? 'Yes'
              : 'No'}
          </p>
        </Col>
      </Row>
    </Modal>
  </div>;
};

export default ViewInvitedUser;
